<template>
  <v-card :height="sideSize" :width="sideSize" class="rounded-xl" dark rounded>
    <v-row align="center" class="fill-height" justify="center" no-gutters>
      <v-col>
        <v-img :alt="`${category.name} category`" :src="require(`@/assets/images/categories/cat${index+1}.png`)"
               class="align-center">
          <v-overlay absolute class="rounded-lg">
            <v-card-text class="text-h6">{{ category.name }}</v-card-text>
          </v-overlay>
        </v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Category from '@/modules/app/models/category'

export default {
  name: 'KurccCategoryCard',
  props: {
    category: {
      type: Category,
      required: true
    },
    index: {
      type: Number
    }
  },
  data () {
    return {
      pageHeight: null
    }
  },
  computed: {
    sideSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 175
        case 'sm':
          return 175
        case 'md':
          return 285
        case 'lg':
          return 285
        case 'xl':
          return 285
        default:
          return 175
      }
    }
  }
}
</script>
